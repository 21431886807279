.sd-description {
  font-style: normal;
  font-family: $font-questiondescription-family;
  font-weight: $font-questiondescription-weight;
  color: $font-questiondescription-color;
  font-size: $font-questiondescription-size;
  line-height: calcLineHeight(1.5);
  white-space: normal;
  word-break: break-word;
}

.sd-element__header .sd-description {
  margin-top: calcSize(0.5);
}