.sd-root-modern {
  --sd-base-padding: calc(5 * #{$base-unit});
  --sd-base-vertical-padding: calc(4 * #{$base-unit});
  --sd-page-vertical-padding: calc(3 * #{$base-unit});
}

.sd-root-modern.sd-root-modern--mobile {
  --sd-base-padding: calc(3 * #{$base-unit});
  --sd-base-vertical-padding: calc(2 * #{$base-unit});
  --sd-page-vertical-padding: calc(2 * #{$base-unit});
}

.sd-root-modern {
  &.sd-root-modern--mobile {
    .sd-element__num {
      @include num_inline();
    }

    .sd-element__title--expandable:before {
      left: calcSize(-2.5);
    }

    .sd-title.sd-container-modern__title {
      flex-direction: column;
    }

    .sd-header__text {
      min-width: 100%;
    }

    //multipletext responsiveness
    .sd-multipletext__cell {
      display: block;

      &:not(:last-child) {
        padding-right: 0;
        padding-bottom: calcSize(2);
      }
    }

    .sd-multipletext__item-container {
      flex-direction: column;
    }

    .sd-multipletext__item-title {
      font-size: calcFontSize(0.75);
      line-height: calcLineHeight(1);
      height: calcSize(2);
      padding-right: 0;
      border-right: none;
      margin-bottom: 0;
      margin-top: calcSize(1);
      width: 100%;
      max-width: none;
    }

    .sd-multipletext__item {
      width: 100%;
    }

    .sd-multipletext__item .sd-input {
      padding-right: 0;
      padding-left: 0;
      margin-top: 0;
      margin-bottom: calcSize(1);
    }

    //eo multipletext responsiveness
    .sd-selectbase--multi-column {
      flex-direction: column;

      .sd-selectbase__column:not(:last-child) {
        padding-right: 0;
      }
    }

    @include table_responsive();
    @include ranking_responsive();
  }
}