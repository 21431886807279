@mixin page_title {
  font-family: $font-pagetitle-family;
  font-weight: $font-pagetitle-weight;
  font-size: $font-pagetitle-size;
  color: $font-pagetitle-color;
  position: static;
  line-height: calcLineHeight(2);
  margin: calcSize(0.5) 0px;
}

@mixin page_description {
  font-family: $font-pagedescription-family;
  font-weight: $font-pagedescription-weight;
  font-size: $font-pagedescription-size;
  color: $font-pagedescription-color;
  position: static;
  line-height: calcLineHeight(1.5);
  margin: calcSize(0.5) 0px;
}

@mixin num_inline {
  float: none;
  margin-inline-start: 0;
  width: auto;
  padding-inline-start: 0;
  padding-inline-end: 0;

  &+span {
    float: none;
    width: auto;
  }
}