@import "../defaultV2-theme/variables.scss";

.sv-button-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: calcFontSize(1);
  overflow: auto;
  border: 1px solid $border;
}

.sv-button-group:focus-within {
  box-shadow: 0 0 0 1px $primary;
  border-color: $primary;
}

.sv-button-group__item {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  appearance: none;
  width: 100%;
  padding: 11px calcSize(2);
  line-height: calcLineHeight(1.5);
  outline: none;
  font-size: calcFontSize(1);
  font-weight: 400;
  background: $background;
  cursor: pointer;
  overflow: hidden;
  color: $foreground;
  position: relative;

  &:not(:last-of-type) {
    border-right: 1px solid $border;
  }
}

.sv-button-group__item--hover:hover {
  background-color: $background-dim;
}

.sv-button-group__item-icon {
  display: block;
  height: calcSize(3);

  use {
    fill: $foreground-light;
  }
}

.sv-button-group__item--selected {
  font-weight: 600;
  color: $primary;

  .sv-button-group__item-icon use {
    fill: $primary;
  }

  &:hover {
    background-color: $background;
  }
}

.sv-button-group__item-decorator {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.sv-button-group__item-caption {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sv-button-group__item-icon+.sv-button-group__item-caption {
  margin-left: calcSize(1);
}

.sv-button-group__item--disabled {
  color: $foreground;
  opacity: 0.25;
  cursor: default;

  .sv-button-group__item-icon use {
    fill: $foreground;
  }

  &:hover {
    background-color: $background;
  }
}

.sv-button-group:focus-within {
  box-shadow: 0 0 0 1px $primary;
  border-color: $primary;
}